import ButtonComponent from "@components/buttonComponent/Button";
import Image from "next/image";
import React, { useEffect, useRef } from "react";
import styles from "./NftCollectionCard.module.css";
import img from "../../../public/images/testing.png";
import { getSaleStage } from "src/utils/isDuringSale";
import { SaleTag } from "@components/NFTItem/SaleTag";
import router from "next/router";
import { getValidUrl, isVideo } from "src/utils/commonCheck";
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';

const NftCollectionCard = ({ projContent, projMojito }: any) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const saleStage = getSaleStage({
    details: {
      startDate: projMojito?.startDate,
      endDate: projMojito?.endDate,
      __typename: "MarketplaceBuyNowOutput",
    },
  });

  useEffect(() => {
    if (videoRef) {
      videoRef.current?.load();
    }
  }, [projContent?.projectImageUrl]);

  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        router.push({
          pathname: `/project/${projMojito?.slug}/${projMojito?.marketplaceID}/${projContent?.mojitoCollectionId}`,
        });
      }}
      className={styles.col_wrp}
    >
      {/* <Image
        layout="responsive"
        alt="col"
        src={"https:" + projContent?.projectImage?.fields?.file?.url}
        width={80}
        height={80}
      /> */}
      <Box sx={{ height: { lg: '620px', sm: 'auto' }, width: 'auto' }}>
        {/* <CardMedia
          component="video"
          sx={{ width: "100%", height: "100%" }}
          image={getValidUrl(isVideo(projContent?.projectImageUrl) ? projContent?.projectImageUrl : projContent?.projectImage?.fields?.file?.url)}
          title="animated-url"
          controls
          autoPlay
        /> */}
        { isVideo(projContent?.projectImageUrl) || isVideo(projContent?.projectImage?.fields?.file?.url) ? (
          <video
            ref={videoRef}
            controls
            loop
            muted
            playsInline
            autoPlay={true}
            className={styles.videoResponsive}
          >
            <source src={getValidUrl(isVideo(projContent?.projectImageUrl) ? projContent?.projectImageUrl : projContent?.projectImage?.fields?.file?.url)} />{" "}
          </video>
        ) : (
          <img style={{ width: '100%', height: '620px', objectFit: 'contain' }} src={ getValidUrl(projContent?.projectImageUrl || projContent?.projectImage?.fields?.file?.url) } alt="Collection" />
        ) }
      </Box>
      <div className={styles.cont}>
        <h3>{projContent?.projectName}</h3>
        {/* <p>
          {projContent?.artistName},{projContent?.year}.
        </p> */}
        {/* <p>Total NFTs : {projMojito?.itemsCount}</p> */}
        {projMojito?.status !== "Active" ? (
          <p className={styles.gray}>Sold Out</p>
        ) : (
          <SaleTag
            item={{
              details: {
                startDate: projMojito?.startDate,
                endDate: projMojito?.endDate,
                __typename: "MarketplaceBuyNowOutput",
              },
            }}
          />
        )}

        <ButtonComponent
          text={"View Details"}
          variant="outlined"
          cls="primary"
          handleClick={() => {
            router.push({
              pathname: `/project/${projMojito?.slug}/${projMojito?.marketplaceID}/${projContent?.mojitoCollectionId}`,
            });
          }}
        />
      </div>
    </div>
  );
};

export default NftCollectionCard;
