import { strings } from "@constants";
import momentTimeZone from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { getSaleStage } from "src/utils/isDuringSale";
import styles from "./NFTItem.module.css";

interface SaleTagProps {
  item?: any;
}
export const SaleTag = ({ item }: SaleTagProps) => {
  const [serverTime, setServerTime] = useState(momentTimeZone());
  const startDate = momentTimeZone(item?.details.startDate);
  const endDate = momentTimeZone(item?.details.endDate);
  const formattedStartDate =
    startDate &&
    momentTimeZone(startDate)
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("MMM Do / H:mm:ss");
  const saleStage = getSaleStage(item);
  const isPreSale = saleStage === "pre";
  const isDuringSale = saleStage === "during";
  const isPostSale = saleStage === "post";

  // console.log(
  //   "startdateeee",
  //   item?.name,
  //   serverTime.toString(),
  //   formattedStartDate
  // );

  let info: string = "";
  if (isDuringSale) {
    switch (item?.details.__typename) {
      case "MarketplaceBuyNowOutput":
        info = "Sale is live";
        break;
    }
  }
  if (isPreSale) {
    switch (item?.details.__typename) {
      case "MarketplaceAuctionLot":
        info = strings.COMMON.BIDDING_STARTS;
        break;
      case "MarketplaceBuyNowOutput":
        info = strings.COMMON.BUYNOW_STARTS;
        break;
    }
    // return <TagText>{formattedStartDate}</TagText>;
    // return (
    //     <p className={styles.salesStart}>
    //         {info + ' ' + formattedStartDate}
    //     </p>
    // );
    return (
      <Countdown
        prefix={info}
        eventTime={startDate}
        serverTime={serverTime}
        interval={1000}
      />
    );
  }
  if (isPostSale) {
    // switch (item?.details.__typename) {
    //     case 'MarketplaceAuctionLot':
    //         info = strings.COMMON.AUCTION_CLOSES;
    //         break;
    //     case 'MarketplaceBuyNowOutput':
    //         info = strings.COMMON.BUYNOW_CLOSES;
    //         break;
    // }
    // return (
    //     <Countdown
    //         prefix={info}
    //         eventTime={endDate}
    //         serverTime={serverTime}
    //         interval={1000}
    //     />
    // );
    switch (item?.details.__typename) {
      case "MarketplaceAuctionLot":
        info = strings.COMMON.AUCTION_FINISHED;
        break;
      case "MarketplaceBuyNowOutput":
        info = strings.COMMON.BUYNOW_FINISHED;
        break;
    }
  }

  return <p className={`${styles.salesStart} saleend`}>{info}</p>;
};

const calculateDuration = (
  eventTime: momentTimeZone.Moment,
  serverTime: momentTimeZone.Moment
) =>
  momentTimeZone.duration(
    (eventTime.unix() - serverTime.unix()) * 1000,
    "milliseconds"
  );

interface CountdownProps {
  prefix: string;
  eventTime: momentTimeZone.Moment;
  serverTime: momentTimeZone.Moment;
  interval: number;
}

function Countdown({
  prefix,
  eventTime,
  serverTime,
  interval,
}: CountdownProps) {
  const [duration, setDuration] = useState(
    calculateDuration(eventTime, serverTime)
  );
  const timerCallback = useCallback(() => {
    setDuration(calculateDuration(eventTime, serverTime.add(1, "seconds")));
  }, [eventTime, serverTime]);

  useEffect(() => {
    const intervalId = setInterval(timerCallback, interval);

    return () => clearInterval(intervalId);
  }, [interval, serverTime, timerCallback]);

  useEffect(() => {
    const timeData = `${duration.hours().toString().padStart(2, "0")} hrs ${duration.minutes().toString().padStart(2, "0")} mins ${duration.seconds().toString().padStart(2, "0")} secs`
    if (timeData?.includes('00 mins 02 secs')) {
      window.location.reload();
    }
  }, [duration]);

  return (
    <>
      {/* {prefix} */}
      <p className={`${styles.salesStart} salestart`}>
        <span className="prefix">
          {prefix}
          <span className="time">
            {" " +
              (duration.days()
                ? `${duration.days().toString().padStart(2, "0")} days `
                : "") +
              `${duration.hours().toString().padStart(2, "0")} hrs ${duration
                .minutes()
                .toString()
                .padStart(2, "0")} mins ${duration
                .seconds()
                .toString()
                .padStart(2, "0")} secs`}
          </span>
        </span>
      </p>
    </>
  );
}
