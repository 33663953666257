import { CollectionItemDataFragment } from "@graphql-generated";
import moment from "moment";

export function getSaleStage(item?: any): "pre" | "during" | "post" {
  if (moment(new Date()).isBefore(item?.details?.startDate)) {
    return "pre";
  } else if (moment(new Date()).isBetween(item?.details?.startDate, item?.details?.endDate)) {
    return "during";
  } else {
    return "post";
  }
}

export function isPreSale(item: CollectionItemDataFragment): boolean {
  return getSaleStage(item) === "pre";
}

export function isDuringSale(item: CollectionItemDataFragment): boolean {
  return getSaleStage(item) === "during";
}

export function isPostSale(item: CollectionItemDataFragment): boolean {
  return getSaleStage(item) === "post";
}
